<template>
    <div>
        <!-- <div class="tools-panel">
            <span  @click="doFolderBack">返回上一级</span>
        </div> -->

        <div v-if="tabValue == 2 && !searchComponent" class="card-container">
            <el-row :gutter="20" v-if="viewer.showFiles.length > 0">
                <el-col :span="5" v-for="doc in viewer.showFiles" :key="doc.id"  >  
                    <el-card :body-style="{ padding: '0px' }" @click.native="handleRowClickEvent(doc)" :class="doc.state == 1 ? 'warm-row document':'document'" >
                        <div class="document-top">
                            <!-- 图片展示 -->
                            <img :src="coverImg(doc)"  v-if='doc.type==0'    style="width:80px;height:70px;margin-top:40px"  alt="doc.name" lazy>
                            <img :src="coverImg(doc)" v-else style="max-height: 100%;width:100%"  alt="doc.name" lazy>
                            <!-- <el-image style="height: 100%;" :src="coverImg(doc)" fit="contain" :alt="doc.name" lazy></el-image> -->
                            <i class="uploading" v-if='doc.state == 1'>上传中</i>
                        </div>
                        <div class="document-middle">
                            <el-tooltip effect="light" :content="doc.name" placement="bottom-end">
                            <div class="title">
                                {{ doc.name }}
                            </div>
                            </el-tooltip>

                            <div class="info-tag">
                                <i class="el-icon-time"></i>{{ doc.createDate | date("yyyy-MM-dd") }}
                            </div>
                            <div class="info-tag">
                                <i class="el-icon-wind-power"></i>{{ doc.suffix || "文件夹" }}
                            </div>
                            <div class="info-tag" v-if="doc.mj">
                                <i class="el-icon-delete"></i>保密
                            </div>
                            <!-- <div class="red_share">
                            <span>阅读数:5次</span>
                            <span>共享数: 2次</span>
                            </div> -->
                        </div>
                        <div class="document-bottom">
                            <el-button class="btn" type="text" title="文档链" @click.stop="handleCommand({type: 'chain', row: doc})" size="mini" icon="el-icon-link"></el-button>
                            
                            <!-- 购物车 -->
                            <!-- <el-button v-if="!cartChecker(doc)" class="btn" type="text" title="添加待共享列表" @click.stop="handleCommand({type: 'addShare', row: doc})"  icon="el-icon-circle-plus-outline" size="mini"></el-button>
                            <el-button v-else class="btn" style="color: #f56c6c;" type="text" title="移除待共享列表" @click.stop="handleCommand({type: 'removeShare', row: doc})"  icon="el-icon-remove-outline" size="mini"></el-button> -->
                            <span v-if="!cartChecker(doc)" title="多选" class="checkbox-span" @click.stop="handleCommand({type: 'addShare', row: doc})"></span>
                            <span v-else class="checkbox-span1" title="多选" @click.stop="handleCommand({type: 'removeShare', row: doc})"><i class="el-icon-check"></i></span>

                            <el-dropdown @command="handleCommand">
                                <span class="el-dropdown-link" @click.stop="">
                                    <el-button class="btn" type="text" title="更多" icon="el-icon-more" size="mini"></el-button>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-edit-outline" :command="{type: 'about', row: doc}">文档详情</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-edit" :command="{ type: 'rename', row:doc }">重命名</el-dropdown-item>
                                    <el-dropdown-item v-if='doc.type == 1' icon="el-icon-download" :command="{ type: 'download', row: doc.id }">下载</el-dropdown-item>
                                    <el-dropdown-item v-if='doc.type == 1' icon="el-icon-document-copy" :command="{ type: 'copysDocument', row: doc.id }">复制文档</el-dropdown-item>
                                    <el-dropdown-item v-if='doc.type == 1' icon="el-icon-refresh" :command="{ type: 'moveDocument', row: doc.id }">移动文档</el-dropdown-item>
                                    <el-dropdown-item v-if='doc.type == 1' icon="el-icon-collection-tag" :command="{ type: 'bindTag', row: doc }">标签管理</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-document-delete" :command="{type: 'delete', row: doc}">删除</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-star-on" v-if="doc.favoriteCount != 0 && doc.type !=0" :command="{type: 'quStart', row: doc}">取消收藏</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-star-off" v-if="doc.favoriteCount == 0 && doc.type !=0" :command="{type: 'stars', row: doc}">收藏</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-sort-up" v-if="doc.sort == null && doc.type !=0" :command="{type: 'sort', row: doc}">置顶</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-sort-down" v-if="doc.sort != null && doc.type !=0" :command="{type: 'nosort', row: doc}">取消置顶</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-share" v-if='doc.suffix != "mp3" && doc.suffix != "mp4" && doc.suffix != "zip"' :command="{type: 'tableshare', row: doc}">共享</el-dropdown-item>
                                    
                                    <el-dropdown-item icon="el-icon-edit" v-if="doc.type!=0 && isEditing == '1' && editingSuffix.indexOf(doc.suffix) !=-1 && editingType== '0' && doc.suffix !='pdf'" :command="{type: 'wpsEdit', row: doc}">在线编辑</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-edit" v-if="doc.type!=0 && isEditing == '1' && editingSuffix.indexOf(doc.suffix) !=-1 && editingType== '1' && doc.suffix !='pdf'" :command="{type: 'onlyOffice', row: doc}">在线编辑</el-dropdown-item>
                                    <!-- <el-dropdown-item icon="el-icon-document" v-if="doc.type!=0" :command="{type: 'tranfromOFD', row: doc}">转换OFD文件</el-dropdown-item> -->
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <el-table
        v-if='tabValue != 2 && !searchComponent && viewer.showFiles.length != 0'
        :data="viewer.showFiles"
        size="small"
        height="calc(100vh - 265px)"
        ref="multipleTable"
        :row-key="row=>row.id"
        @selection-change="handleSelectionChange"
        :row-class-name="tableRowClassName"
        @cell-mouse-enter="handleCellEnterEvent"
        @cell-mouse-leave="handleCellLeaveEvent"
        style="width: 100%;margin-bottom: 20px;">
            <el-table-column
            type="selection"
            :reserve-selection="true"
            width="50"
            align="center">
            </el-table-column>
            <el-table-column
                label="文件名"
                width="400">
                <template slot-scope="scope">
                    <div class="file-box" @click="handleRowClickEvent(scope.row)">
                        <div>
                            <img :src="coverImg(scope.row)" v-if='scope.row.type==0' lazy>
                            <img :src="thumbIcon(scope.row)" v-else lazy>
                            <span>{{scope.row.name}}</span>
                             <i class="uploading" v-if='scope.row.state == 1'>上传中</i>
                        </div>
                        <div class="btns" v-show="isShowCellFlag && (rowCellInfo.id == scope.row.id)">
                            <el-button type="text" title="文档链" @click.stop="handleCommand({type: 'chain', row: scope.row})" icon="el-icon-link"></el-button>
                            
                            <!-- 购物车 -->
                            <!-- <el-button v-if="!cartChecker(scope.row)" style="margin-left: 20px;" type="text" title="添加待共享列表" @click.stop="handleCommand({type: 'addShare', row: scope.row})"  icon="el-icon-circle-plus-outline"></el-button>
                            <el-button v-else-if="cartChecker(scope.row)" style="color: #f56c6c;margin-left: 20px;" type="text" title="移除待共享列表" @click.stop="handleCommand({type: 'removeShare', row: scope.row})"  icon="el-icon-remove-outline"></el-button> -->
                        
                            <el-dropdown @command="handleCommand">
                                <span class="el-dropdown-link" @click.stop="">
                                    <el-button style="margin-left: 20px;" type="text" title="更多" icon="el-icon-more"></el-button>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-edit-outline" :command="{type: 'about', row: scope.row}">文档详情</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-edit" :command="{ type: 'rename', row: scope.row }">重命名</el-dropdown-item>
                                    <el-dropdown-item v-if='scope.row.type == 1' icon="el-icon-download" :command="{ type: 'download', row: scope.row.id }">下载</el-dropdown-item>
                                    <el-dropdown-item v-if='scope.row.type == 1' icon="el-icon-document-copy" :command="{ type: 'copysDocument', row: scope.row.id }">复制文档</el-dropdown-item>
                                    <el-dropdown-item v-if='scope.row.type == 1' icon="el-icon-refresh" :command="{ type: 'moveDocument', row: scope.row.id }">移动文档</el-dropdown-item>
                                    <el-dropdown-item v-if='scope.row.type == 1' icon="el-icon-collection-tag" :command="{ type: 'bindTag', row: scope.row }">标签管理</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-document-delete" :command="{type: 'delete', row: scope.row}">删除</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-edit" v-if="scope.row.type!=0 && isEditing == '1' && editingSuffix.indexOf(scope.row.suffix) !=-1 && editingType== '0'" :command="{type: 'wpsEdit', row: scope.row}">在线编辑</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-edit" v-if="scope.row.type!=0 && isEditing == '1' && editingSuffix.indexOf(scope.row.suffix) !=-1 && editingType== '1'" :command="{type: 'onlyOffice', row: scope.row}">在线编辑</el-dropdown-item>
                                    <!-- <el-dropdown-item icon="el-icon-document" v-if="scope.row.type!=0" :command="{type: 'tranfromOFD', row: scope.row}">转换OFD文件</el-dropdown-item> -->
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="文件类型"
                align="center">
                <template slot-scope="scope">
                    {{ scope.row.suffix || "文件夹" }}
                </template>
            </el-table-column>
            <el-table-column
                prop="ownerName"
                label="所有者"
                align="center">
            </el-table-column>
            <el-table-column
                prop="sort"
                label="置顶顺序"
                align="center">
                <template slot-scope="scope">
                    {{ scope.row.sort || "无" }}
                </template>
            </el-table-column>
            <el-table-column
                label="操作时间"
                align="center">
                <template slot-scope="scope">
                    {{scope.row.createDate |date}}
                </template>
            </el-table-column>
            <el-table-column
            v-if='!$isRunInsert'
            label="操作"
            width="150"
            align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click.stop="handleCommand({type: 'quStart', row: scope.row})" v-if="scope.row.startShowFlag != undefined && scope.row.type != 0 ">
                        <!-- <i class="el-icon-star-on"></i> -->
                        取消收藏
                    </el-button>
                    <el-button type="text" size="small" @click.stop="handleCommand({type: 'stars', row:  scope.row})" v-if="scope.row.startShowFlag == undefined && scope.row.type != 0 ">
                        <!-- <i class="el-icon-star-off"></i> -->
                        收藏
                    </el-button>
                    <el-button v-if='scope.row.suffix != "mp3" && scope.row.suffix != "mp4" && scope.row.suffix != "zip"' type="text" size="small" @click.stop="handleCommand({type: 'tableshare', row:  scope.row})">
                        <!-- <i class="el-icon-share"></i> -->
                        共享
                    </el-button>
                    <el-button type="text" size="small" v-if='scope.row.sort == null && scope.row.type != 0' @click.stop="handleCommand({type: 'sort', row:  scope.row})">
                        置顶
                    </el-button>
                     <el-button type="text" size="small" v-if='scope.row.sort != null && scope.row.type != 0' @click.stop="handleCommand({type: 'nosort', row:  scope.row})">
                       取消置顶
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="no-data-box" v-if="viewer.showFiles.length == 0">
            <img src="@/assets/no-file.svg" alt="">
            <span>暂无数据</span>
        </div>

        <!-- 检索组件 -->
        <SearchComponent ref='SearchComponent' v-if='searchComponent' :viewer='viewer'></SearchComponent>

        <div class="pagination" v-if="viewer.showFiles.length > 0">
            <el-pagination
            class="pagebox"
            @current-change="pageChanged"
            :page-size="pageSize"
            layout="total,  prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>

        <!-- 文件名称操作 -->
        <FolderName ref="folderName" :viewerKey="viewerKey" :renameId="renameId" datatype="doc"></FolderName>

        <!-- <ShareDialog ref="shareDialog"></ShareDialog>-->
        <FilePropsDialog ref="filePropsDialog"></FilePropsDialog>
        <FileDownloadDialog ref="fileDownloadDialog"></FileDownloadDialog>
        <FileChainDialog ref="fileChainDialog"></FileChainDialog>
        <!-- <FilePrinter ref="FilePrinter"></FilePrinter> -->
        <UploadFile ref="uploadFile"></UploadFile>

        <AddDocumentDialog ref="addDocumentDialog" :documents="filterFiles" @onItemClicked="onItemClicked" 
        :checkedMap="docIdMap" :newViewer="newViewers" @backAppiot="removeFile" @doFolderBacks="doFolderBacks" :selectFiles="selectFiles"
        :showCheckbox="showCheckbox" @closeEvent="closeEvent"></AddDocumentDialog>

        <BindTag ref="bindTagPage"></BindTag>
    
        <CreateFile ref="createFile" @createOver='loadAndRefresh'/>
        <OnlyOffice ref='onlyOffice' @createOver='loadAndRefresh'></OnlyOffice>
        <ChangeFileType ref="changeFileType"  @createChangeOver="loadAndRefresh"></ChangeFileType>

        <!-- 排序 -->
        <FileSort ref='FileSort'  @loadAndRefresh='loadAndRefresh'></FileSort>
     
    </div>
   
</template>
<script>
import defaultImg from '@/assets/folder.png'
import morenImg from '@/assets/moren.png'

// import TableViewer from "./FilesTableViewer.vue";
// import ContextMenu from "./ContextMenu.vue";
// import ShareManager from "./ShareManager.vue";
import FolderName from "../files/FolderName.vue";

import FilePropsDialog from "../files/FileProps.vue";
import FileDownloadDialog from "../files/Download.vue";
import UploadFile from "@/components/UploadFile.vue"
import FileChainDialog from "../files/FileChain.vue";
import FilePrinter from "../files/FilePrinter.vue";
import ShareToMe from "../files/ShareToMe.vue";
import { number, time } from 'echarts';
import {shareAPI, dollectionAPI,documentAPI ,msgAPI} from 'liankong-ui-api' 
import { Store } from '@lk/cache'
import AddDocumentDialog from '../files/AddDocumentDialog.vue'
import BindTag from './components/BindTag.vue'
import ChangeFileType from './ChangeFileType.vue'
import CreateFile from './components/CreateFile.vue'
import OnlyOffice from './components/OnlyOffice.vue'

import iconDefault from '@/assets/img/icon-def.png'
import iconUnknown from '@/assets/img/icon-unknown.png'
import iconImage from '@/assets/img/icon-picture.png'
import iconOFD from '@/assets/img/icon-ofd.png'
import iconAudio from '@/assets/img/icon-audio.png'
import iconWord from '@/assets/img/icon-word.png'
import iconPpt from '@/assets/img/icon-ppt.png'
import iconPdf from '@/assets/img/icon-pdf.png'
import iconMP3 from '@/assets/doc-icon/mp3.png'
import iconMP4 from '@/assets/doc-icon/mp4.png'
import iconZIP from '@/assets/doc-icon/zip.png'
import iconExcl from '@/assets/img/icon-excl.png'
import SearchComponent from '../files/Search.vue'
import FileSort from './components/FileSort.vue';

//后缀映射
const suffixIconCache = {
  def: iconDefault,
  unknown: iconUnknown,

  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,

  ofd: iconOFD,

  doc: iconWord,
  DOC: iconWord,
  docx: iconWord,

  mp3: iconMP3,
  mp4: iconMP4,
  zip: iconZIP,
  ppt:iconPpt,
  pptx:iconPpt,
  pdf:iconPdf,
  xls:iconExcl,
  xlsx:iconExcl
}

export default {
    data(){
        return {
            tabValue: 1, // tab切换视图还是列表
            vals: '全部',
            systemTag: [],
            newObj: {},
            // pageNum: 1,
            pageSize: 10,
            textVal: '', // 文字搜索
            form: {
                type: [],
            },
            viewerKey: 'my',
            cartViewKey: "share",
            renameId:'',
            //选择配置
            options: {
                //文件数
                count: Number.MAX_VALUE,
                /**
                 * 筛选类型
                 * 默认包含文件夹和文件
                 * 0：文件夹
                 */
                type: null,

                //回调函数
                callback: "onFileSelected"
                // callback: "myDialogList"
            },
            //选择文件
            selectFiles: [],
            //已选文档id缓存
            docIdMap: {},
            isMove: true,
            docuInfoId: '',
            // 获取个人文档- 弹框 的视图
            newViewerKey: 'fileSelector',
            showCheckbox: false,
            multipleSelection: [],
            rowCellInfo: {}, // 鼠标滑过的表格信息
            isShowCellFlag: false,
            folderCounts: 0 , // 记录进入目录的数
            startIdArray: [], // 收藏id集合
            newFolerObjecInfo: {},
            isEditing:'0',
            editingSuffix:['xlsx','xls','docx','doc','ppt','pptx','wps','pdf','rtf','csv'],
            editingType:null,
             searchComponent:false,
             searchForm:null
        }
    },
    inject: ["setQuery"],
    components: {
        // TableViewer,
        // ContextMenu,
        // ShareManager,
        FolderName,
        ChangeFileType,
        UploadFile,
        FilePropsDialog,
        FileDownloadDialog,
        FileChainDialog,
        FilePrinter,
        ShareToMe,
        AddDocumentDialog,
        BindTag,
 
        CreateFile,
        OnlyOffice,
        SearchComponent,
        FileSort
    },
    computed: {
        viewer() {
            return this.$store.state.doc.viewer[this.viewerKey] 
        },
        newViewers() {
            return this.$store.state.doc.viewer[this.newViewerKey] // 弹框
        },
        checkedMap(){
            return this.$store.state.cart.views[this.cartViewKey].docIdMap
        },
        // pageSize() {
        //     return this.$store.state.doc.viewer[this.viewerKey].query.pageSize || 0
        // },
        total() {
            return this.$store.state.doc.viewer[this.viewerKey].paging.total || 0
        },
        //购物车检查，判断是否选中
        cartChecker(){
            return (doc) => {
                const mapKey = doc.id
                return this.checkedMap[mapKey]
            }
        },
        coverImg(){
            return (doc) => doc.type == 1 ?(doc.coverImg!=null? this.$options.filters["thumbnailUrl"](doc.coverImg):morenImg ): defaultImg
        },
        // 文档类型过滤 -- 弹框
        filterFiles(){
            let that = this
            const newShowFiles = this.newViewers ? this.newViewers.showFiles : []
            newShowFiles.map( items =>{
                that.$set(items,'isChecked',false)
            })
            return this.options.type == null ? newShowFiles : newShowFiles.filter(f => f.type == this.options.type)

        }
    },
    watch: {
        '$store.state.checkedLeftAsideInfo'(newValue) {
            this.searchForm = {
                title: '',
                type: '全部'
            }
            this.leftFileClickEvent(newValue);
        },
        startIdArray: {
            handler(newlist,oldlist) {
                this.formatStartEvents(newlist, this.viewer.showFiles)
            },
            deep: true
        }
    },
    created() {
        this.onLoad();
        this.$ebus.$on('cart:remove', (file) => {
            this.$nextTick(() => this.$forceUpdate())
        })  
        this.$store.commit('clearFolderBreadList')
    },
    mounted(){
        this.$ebus.$on('clear',res=>{
            this.$nextTick(() => this.$forceUpdate())

            if(this.tabValue == 1) {
                this.toggleSelection(res)
            }
        })
        this.leftFileClickEvent(this.$store.state.checkedLeftAsideInfo);

        // 上传
        this.$ebus.$on('uploadFileBtn',res=>{
            if(this.tabValue == 1 || this.tabValue == 2) {
                this.$nextTick(()=>{
                    this.handleCommand({type: 'upload'});
                })
            }
        })
        // 新建
        this.$ebus.$on('addNewFileBtn', res=>{
            if(this.tabValue == 1 || this.tabValue == 2) {
                this.handleCommand({type: 'mkdir'});
            }
        }),
                // 新建文件
        this.$ebus.$on('createFile', res=>{
            if(this.tabValue == 2) {
                this.$nextTick(()=>{
                    this.$refs.changeFileType.init();
                })
            } else if(this.tabValue == 1) {
                this.$nextTick(()=>{
                    this.$refs.changeFileType.init();
                })
            }
        }),
        // 搜索
        this.$ebus.$on('changeRadioEvent', val=>{
            this.vals = val;
            this.searchEvent(val, this.textVal);
        })
        this.$ebus.$on('changeInputEvent', textVal=>{
            this.pageNum = 1;
            this.searchForm = textVal;
            this.searchComponent = textVal.title != '' && textVal.title != null ? true : false;
           
            this.searchEvent(this.vals, textVal);
        })
        // tab切换
        this.$ebus.$on('callBackTab', res=>{
            let that = this;
            this.tabValue = res;

            if(this.tabValue == 1) {
                this.multipleSelection.forEach(keys=>{
                    this.viewer.showFiles.forEach(row=>{
                        if(row.id == keys.id) {
                            setTimeout(()=>{
                                that.$refs.multipleTable.toggleRowSelection(row, true);
                            }, 500)
                        }
                    })
                })
            } else {
                this.viewer.showFiles.forEach(items=>{
                    this.$store.commit("cart/remove", {
                        viewerKey: this.cartViewKey,
                        doc: items,
                    })
                })
        
                this.multipleSelection.forEach(keys=>{
                    this.viewer.showFiles.forEach(row=>{
                        if(row.id == keys.id) {
                            this.$store.commit("cart/push", {
                                viewerKey: this.cartViewKey,
                                doc: row,
                            })
                        }
                    })
                })
                this.$nextTick(() => this.$forceUpdate())
            }
        })
        this.$ebus.$on('myChildDocBackBtn', text=>{
            this.doFolderBack()
        })
        this.$ebus.$on('myDocEnterChild', item=>{
            if (item.data.type == 0) {
                this.$store.dispatch("doc/enterFolder", {
                    vkey: this.viewerKey,
                    folder: item.data
                });
            }
            if(item.data.children.length > 0) {
                this.$store.commit('folderBreadDeleteNum', {id: item.path, vkey: 'my'})
            }
        })
        this.$ebus.$on('enterMyDocData', tips=>{
            if(tips == '我的文档') {
                this.$store.commit('pageBackTextShow', '')
                this.$store.commit('clearFolderBreadList')
                this.viewer.depth = 0;
                this.$store.state.doc.viewer[this.viewerKey].depth = 0;
                this.$store.state.doc.viewer[this.viewerKey].paths = [];
                this.$router.push({
                    path: '/file/mydoc'
                })
                // 存储到vuex
                this.$store.commit('isActiveNameLeftMenu', '我的文档')
            } 
        })

         // 共享
        this.$ebus.$on('handleMyShareEvent', val=>{
            this.shareMyDoc()
            
        })

        this.$ebus.$on('handleMyDocRemoveClickEvent', val=>{
            this.handleMyDocRemoveClickEvent();
        })

        this.getStartIDArray();
    },
    methods: {
        tableRowClassName({row}){
            if(row.state == 1){
                return 'warm-row'
            }
        },
        //加载或刷新当前文件夹
         loadAndRefresh() {
            this.$store.dispatch("doc/loadChildren", {
                vkey: this.viewerKey,
                reset: true,
                pageSize:10
            });
        },
        // 左侧文件夹点击跳转到对应的文件夹内
        leftFileClickEvent(data) {
            if(data.type == 0) {
                this.handleEnterFolder(data)
            }

            setTimeout(()=>{
                this.getStartIDArray()
                this.formatStartEvents(this.startIdArray, this.viewer.showFiles)
            }, 100)
        },
        searchEvent(vals, textVal){
            this.systemTag = [];
            this.systemTag.push(vals);
            if(textVal){
                this.newObj = {
                    miji: [],
                    systemTag: textVal.type == '全部' ? [] : [textVal.type],
                    keyword: textVal.title,
                    target: textVal.target,
                    doctype:0,
                    parentId:this.$store.state.folderBreadList.length>0? this.$store.state.folderBreadList[0].data['id'] : 0
                }
            }else{
                 this.newObj = {
                    miji: [],
                    systemTag: [],
                    keyword: '',
                    target: '',
                    doctype:0,
                    parentId:this.$store.state.folderBreadList.length>0? this.$store.state.folderBreadList[0].data['id'] : 0
                }
            }
           
            this.setQuery(this.newObj, {
                pageNum: this.pageNum,
                pageSize: this.pageSize
            });
        },
        // 获取收藏ids的集合
        getStartIDArray(){
            dollectionAPI.dollectionAll().then(res=>{
                if(res.code == 0) {
                    this.startIdArray = res.data;
                }
            })
        },
        // 图标
        thumbIcon(doc){
            let iconKey = "unknown"
            if(suffixIconCache.hasOwnProperty(doc.suffix)){
                iconKey = doc.suffix
            }
            return suffixIconCache[iconKey] 
        },
        // 行点击
        handleRowClickEvent(row, column, event) {
            if(row.state == 1) return
            if(row.suffix == 'zip'){
              this.$message.error('zip格式不支持阅读！')
              return
            }
            // 指定格式用wps或者onlyoffice阅读
            if(this.editingSuffix.indexOf(row.suffix) !=-1 && this.isEditing == '1' ){
                row.isStream = 1
            }else{
                row.isStream = 2
            }
            if(row.isStream==1){
                if(this.editingType== '0'){
                    documentAPI.wpsOpenFile({
                        fileId:row.id
                    }).then(res=>{
                        if(res.code==0){
                            let title = '在线编辑'
                            if(row.suffix == 'pdf'){
                                title = 'PDF阅读'
                            }
                            if(Store.get("myPreference")){
                                if(Store.get("myPreference").reader_model == '1'){
                                    const routeData = this.$router.resolve({
                                        path:'/wps',
                                        query:{
                                            urls:res.data
                                        }
                                    })
                                    window.open(routeData.href,'_blank');
                                }else{
                                     this.$refs['createFile'].init(res.data,title)
                                }
                            }else{
                                 this.$refs['createFile'].init(res.data,title)
                            }
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }else{
                    documentAPI.getOnlyOfficeParameter(row.id).then(res=>{
                        if(res.code==0){
                             if(Store.get("myPreference")){
                                if(Store.get("myPreference").reader_model == '1'){
                                    const routeData = this.$router.resolve({
                                        path:'/onlyOffice',
                                        query:res.data
                                    })
                                    window.open(routeData.href,'_blank');
                                }else{
                                    this.$refs['onlyOffice'].init(res.data)
                                }
                            }else{
                                this.$refs['onlyOffice'].init(res.data)
                            }
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }
            } else {
              this.handleCommand({type: 'click', row: row});
            }
            // this.handleCommand({type: 'click', row: row});
        },
        // 表格选择
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 共享
        shareMyDoc(){
            if(this.tabValue == 1) {
                if(this.multipleSelection.length > 0) {
                    this.multipleSelection.forEach(mulItem=>{
                        this.$store.commit("cart/push", {
                            viewerKey: this.cartViewKey,
                            doc: mulItem,
                        })
                        this.$nextTick(() => this.$forceUpdate())
                    })
                    this.$ebus.$emit('showMaximize')
                    this.$store.commit('setCartShow', true)
                } else {
                    this.$message.error('请选择数据')
                }
            } else {
                if(JSON.stringify(this.checkedMap) != '{}') {
                    this.$store.commit('setCartShow', true)
                } else {
                    this.$message.error('请选择数据')
                }
            }
        },
        // 移除 取消选择
        toggleSelection(rows){
            let that = this;
            if (rows) {
                this.viewer.showFiles.forEach(row => {
                    if(row.id == rows.id) {
                        if(that.$refs.multipleTable) {
                            that.$refs.multipleTable.toggleRowSelection(row, false);
                        }
                    }
                });
            } else {
                that.$refs.multipleTable.clearSelection();
            }
        },
        // 鼠标划入
        handleCellEnterEvent(row, column, cell, event) {
            this.isShowCellFlag = true;
            this.rowCellInfo = row;
        },
        // 鼠标滑出
        handleCellLeaveEvent(row, column, cell, event) {
            this.isShowCellFlag = false;
        },
        // 分页
        pageChanged(num) {
            this.pageNum = num;
            this.searchEvent(this.vals, this.searchForm);
           
            
        //    this.$emit('pageChange', num);
        },
        //文档集中处理器
        handleCommand({type, row}) {
            switch (type) {
                case "click":
                    if (row.type == 0) {
                        //打开文件夹
                        this.handleEnterFolder(row);
                    } else {
                        if(row.documentId !=null){
                            row.id =row.documentId
                        }
                        this.$ebus.$emit('publicReader',{
                            shareId:row.shareId, 
                            id:row.documentId || row.id,
                            documentId: row.documentId,
                            name:row.name,
                            suffix:row.suffix
                        })
                      
                    }
                break;
                case "rename":
                    this.renameId = row.id;
                    this.$refs["folderName"].show({ mode: "rename",value:row.name});
                    break;
                case "reload":
                    this.$store.dispatch("document/loadChildren");
                    break;
                //添加共享购物车
                case "addShare":
                    this.$store.commit("cart/push", {
                        viewerKey: this.cartViewKey,
                        doc: row,
                    })
                    this.multipleSelection.push(row);
                    this.$nextTick(() => this.$forceUpdate())
                    break
                //移除共享购物车
                case "removeShare":
                    this.$store.commit("cart/remove", {
                        viewerKey: this.cartViewKey,
                        doc: row,
                    })
                    this.multipleSelection.forEach((filItems, ind)=>{
                        if(filItems.id == row.id) {
                            this.multipleSelection.splice(ind, 1)
                        }
                    })
                    this.$nextTick(() => this.$forceUpdate())
                    break
                case "upload":
                    this.$ebus.$emit('clearSelect')
                    this.$refs["uploadFile"].show();
                    break;
                case "share":
                    //多选文件处理
                    const shareFiles = [row];

                    if (this.$store.state.document.viewer.selection) {
                        this.$store.state.document.viewer.selectionRows.map((item) => {
                            if (item.id != row.id) {
                                shareFiles.push(item);
                            }
                        });
                    }

                    this.$refs["shareDialog"].show({
                        mode: "new",
                        value: shareFiles,
                    });
                    break;
                case "mkdir":
                    this.$ebus.$emit('clearSelect')
                    this.$refs["folderName"].show({ mode: "new", value: null });
                    break;
                case "about":
                    this.$refs["filePropsDialog"].show(row);
                    break;
                case "download":
                    this.$refs["fileDownloadDialog"].show(row);
                    break;
                case "delete":
                    this.loadingShow("删除中...")
                    this.$store.dispatch('doc/checkDocumentHasShare',[row.id]).then((res)=>{
                    let {data:hasData,message:hasMessage,code:hasCode} = res
                    if(hasCode==0){
                        if(hasData.length>0){
                            this.$confirm('删除文件中存在共享文件, 是否继续删除?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                            }).then(() => {
                               this.removeFewMyFile(row)
                            }).catch(() => { 
                                this.loadingHide()    
                            });
                        } else {
                               this.removeFewMyFile(row)
                        }
                    } else {
                        this.$message.error(hasMessage)
                    }
                })
                    break;
                case "selection":
                    this.$store.commit("doc/setSelection", {
                        value: true,
                        item: row,
                    });
                    break;
                case "chain":
                    this.$refs["fileChainDialog"].show('doc', row);
                    break;
                case "printer":
                    this.$refs["FilePrinter"].show(row);
                    break;

                case "moveDocument": // 移动文档
                    this.isMove = false;
                    this.options.type = 0;
                    this.docuInfoId = row;
                    this.$refs["addDocumentDialog"].show(this.viewerKey);
                    break;

                case "copysDocument": // 复制文档
                    this.isMove = true;
                    this.options.type = 0;
                    this.docuInfoId = row;
                    this.$refs["addDocumentDialog"].show(this.viewerKey);
                    break;

                case "stars":
                    this.addFavorite(row);
                    break;

                case "quStart":
                    this.quStarData(row);
                break;

                case "bindTag":
                    this.bindTagEvent(row);
                break;
                 case 'sort':
                    this.$refs.FileSort.show(row)
                    break;
                case 'nosort':
                     documentAPI.cancelMyDocumentFileSort(row.id).then(res => {
                        if(res.code == 0){
                            this.$message.success('操作成功！');
                            this.loadAndRefresh()
                        }else{
                            this.$message.error(res.message)
                        }
                    })
                    break;

                case "tableshare":
                    this.$refs.multipleTable.clearSelection();
                    this.multipleSelection = []
                    this.viewer.showFiles.forEach(items=>{
                        this.$store.commit("cart/remove", {
                            viewerKey: this.cartViewKey,
                            doc: items,
                        })
                    })

                    this.$store.commit("cart/push", {
                        viewerKey: this.cartViewKey,
                        doc: row,
                    })
                     this.$ebus.$emit('showMaximize')
                    this.$store.commit('setCartShow', true)
                break;
                case "tranfromOFD": 
                this.loadingShow("转换中...")
                  this.$store.dispatch('doc/transformToOFD',row.id).then(res=>{
                    this.loadingHide()
                    if(res.code==0){
                        this.$message.success('转换成功')
                    } else {
                        this.$message.error('转换失败')
                    }
                  })
                break;
                case 'wpsEdit': 
                    documentAPI.wpsOpenFile({
                            fileId:row.id
                        }).then(res=>{
                            if(res.code==0){
                               if(Store.get("myPreference")){
                                    if(Store.get("myPreference").reader_model == '1'){
                                        const routeData = this.$router.resolve({
                                            path:'/wps',
                                            query:{
                                                urls:res.data
                                            }
                                        })
                                        window.open(routeData.href,'_blank');
                                    }else{
                                        this.$refs['createFile'].init(res.data,'文件编辑')
                                    }
                                }else{
                                    this.$refs['createFile'].init(res.data,'文件编辑')
                                }
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                  break;
                case 'onlyOffice': 
                    documentAPI.getOnlyOfficeParameter(row.id).then(res=>{
                        if(res.code==0){
                             if(Store.get("myPreference")){
                                if(Store.get("myPreference").reader_model == '1'){
                                    const routeData = this.$router.resolve({
                                        path:'/onlyOffice',
                                        query:res.data
                                    })
                                    window.open(routeData.href,'_blank');
                                }else{
                                    this.$refs['onlyOffice'].init(res.data)
                                }
                            }else{
                                this.$refs['onlyOffice'].init(res.data)
                            }
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                break;
            }
        },
        // 进入指定目录
        handleEnterFolder(folder) {
            this.newFolerObjecInfo = folder;
            this.folderCounts++;
            // 文件夹的名称存起来
            this.$store.commit('folderBreadList', {doc: folder, tip: '我的文档child'});

            if (folder) {
                if (folder.type == 0) {
                    this.searchComponent = false;
                    this.$store.dispatch("doc/enterFolder", {
                        vkey: this.viewerKey,
                        folder,
                        pageSize:10,
                        pageNum:1
                    });
                }
            } else {
                this.$store.dispatch("doc/enterFolder");
            }

            if(this.viewer.depth > 0) {
                this.$store.commit('pageBackTextShow', '文档child')
            }
        },
        // 后退
        doFolderBack(){
            this.folderCounts--;
            this.$store.dispatch("doc/backFolder", {
                vkey: this.viewerKey,
            })
            this.loadAndRefresh()
            if(this.folderCounts == 0) {
                this.$router.push({
                    path: '/file/mydoc'
                })
                // 存储到vuex
                this.$store.commit('myDocGoBack', 'back')
                // 存储到vuex
                this.$store.commit('isActiveNameLeftMenu', '我的文档')

                this.$store.commit('pageBackTextShow', '')
            }

            this.$store.commit('backFolerBread')
        },
        //文件目录返回
        doFolderBacks() {
            let lastCacheKey = this.selectFiles[this.selectFiles.length-1] ? this.selectFiles[this.selectFiles.length-1].id : 0
            if (this.docIdMap[lastCacheKey]) {
                this.selectFiles.splice(this.selectFiles.length-1)
                delete this.docIdMap[lastCacheKey]
            }
            this.$store.dispatch("doc/backFolder", {
                vkey: this.newViewerKey
            })
        },
        prev() {
            this.$store.dispatch("doc/doLoadPrevPage", {
                vkey: this.viewerKey,
                reset:true
            });
        },
        next() {
            this.$store.dispatch("doc/doLoadNextPage", {
                vkey: this.viewerKey,
            });
        },
        //列表项点击
        onItemClicked(key, docInfo, state) {
            this.showCheckbox = true
            switch (key) {
                case "click":
                    //列表项点击
                    if (docInfo.type == 1) {
                        //文件单次点击勾选，再次点击取消
                        const states = !this.docIdMap.hasOwnProperty(docInfo.id)
                        this.onFileChecked(docInfo, states)
                    
                    } else {
                        //进入文件夹
                        this.$store.dispatch("doc/enterFolder", {
                            vkey: this.newViewerKey,
                            folder: docInfo,
                        })
                    }
                    break;
                
                case "checked":
                    this.onFileChecked(docInfo, state)
                    break
            }
        },
        //勾选文件，单次点击勾选，再次点击取消
        onFileChecked(doc, state){
            this.addFile(doc)
        },

        //勾选文件
        addFile(doc){
            const cacheKey = doc.id
            if (!this.docIdMap[cacheKey]) {
                this.docIdMap[cacheKey] = doc
                this.selectFiles.push(doc)
            }
        },

        //移除文件
        removeFile(data){
            let {item:doc,index} = data
            const cacheKey = doc.id
           
            //判断是否已添加
            if (this.docIdMap[cacheKey]) {
                if(this.selectFiles.length>1&&index!=0){
                    for (let itemIndex = 0, len = this.selectFiles.length; itemIndex < len; itemIndex++) {
                        if (this.selectFiles[itemIndex].id == cacheKey) {
                            this.selectFiles.splice(itemIndex)
                            this.$store.dispatch("doc/enterFolder", {
                                vkey: this.newViewerKey,
                                folder:this.selectFiles[this.selectFiles.length-1]
                            })
                            this.$store.state.doc.viewer[this.newViewerKey].depth = this.selectFiles.length
                            this.$store.state.doc.viewer[this.newViewerKey].paths.forEach((item,index)=>{
                                if(item.id==cacheKey){
                                    this.$store.state.doc.viewer[this.newViewerKey].paths.splice(index)
                                }
                            })
                            delete this.docIdMap[cacheKey]
                            break
                        }
                    }
                } else {
                    this.selectFiles.forEach(item=>{
                        delete this.docIdMap[item.id]
                    })
                    this.$store.state.doc.viewer[this.newViewerKey].current = 0;
                     this.$store.dispatch("doc/loadChildren", {
                        vkey: this.newViewerKey,
                        reset: true,
                        pageSize:10
                    })
                    this.selectFiles = []
                    this.$store.state.doc.viewer[this.newViewerKey].depth = 0
                    this.$store.state.doc.viewer[this.newViewerKey].paths = [];
                }
            }
        },
        // 清空事件
        closeEvent(){
            this.onLoad()

            this.selectFiles.forEach(a=>{
                this.$set(a,'isChecked',false)
                this.docIdMap[a.id].isChecked = false
            })
            this.docIdMap = {}
            this.selectFiles = []
            this.showCheckbox = false    

        },
         // 添加收藏
        addFavorite(docInfo) {
            this.$store.dispatch("collection/addDollection", docInfo.id)
                .then((data) => {
                    this.$message.success("收藏成功");
                    this.getStartIDArray();
                    this.formatStartEvents(this.startIdArray, this.viewer.showFiles);
                }).catch((err) => {
                    this.$msg.error("收藏发生错请稍后再试");
                });
        },
        // 取消收藏
        quStarData(item) {
            dollectionAPI.removeRecoredId(item.id).then(res=>{
                if(res.code == 0) {
                    this.$message.success("取消收藏成功");
                    this.getStartIDArray();
                    // this.formatStartEvents(this.startIdArray, this.viewer.showFiles); 
                    delete item.startShowFlag   
                } else {
                    this.$message.error(res.message); 
                }
            }).catch(error=>{
                this.$message.error(error);
            })
        },

        bindTagEvent(row) {
            this.$refs.bindTagPage.disableHidden = true;
            this.$nextTick(()=>{
                this.$refs.bindTagPage.init(row);
            })
        },
        /**
         * 页面加载
         * options: {
         *    count: 选择文件数，默认不限制
         *    type: null, 选择类型，默认包含文件夹和文件， 0=文件夹
         *    callback: 回调函数，默认为 onFileSelected
         * }
         */ 
        onLoad(){
            //数据类型处理
            this.options.count = 1
            if(this.options.count) this.options.count = Number(this.options.count)

            this.options = Object.assign(this.options, this.options)
            
            this.$store.commit("doc/createViewer", {
                viewerKey: this.newViewerKey
            })
            this.$store.dispatch("doc/loadChildren", {
                vkey: this.newViewerKey,
                reset: true,
                fileType:'0'
            })
            this.getValueByKey()
        },
        // 收藏状态判断
        formatStartEvents(ids, list) {
            let falg = false;
            for (var i = 0; i < list.length; i++) {
                delete list[i].startShowFlag
                for (var j = 0; j < ids.length; j++) {
                    if (list[i].id == ids[j]) {
                        falg = true;
                        this.$set(list[i], 'startShowFlag', falg);
                    }
                }
            }
        },
        // 多删除
        handleMyDocRemoveClickEvent(){
            if(this.tabValue == 1) {
                if(this.multipleSelection.length > 0){
                    let ids = []
                    this.multipleSelection.map((mapItems)=>{
                        ids.push(mapItems.id)
                    })
                    this.$store.dispatch('doc/checkDocumentHasShare',ids).then((res)=>{
                    let {data:hasData,message:hasMessage,code:hasCode} = res
                    if(hasCode==0){
                        if(hasData.length>0){
                            this.$confirm('删除文件中存在共享文件, 是否继续删除?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                            }).then(() => {
                               this.removeMoreMyFile(ids)
                            }).catch(() => {         
                            });
                        } else {
                               this.removeMoreMyFile(ids)
                        }
                    } else {
                        this.$message.error(hasMessage)
                    }
                })

                } else {
                    this.$message.error('请选择数据')
                }
            } else {
                if(JSON.stringify(this.checkedMap) != '{}'){
                    let ids = []
                    this.viewer.showFiles.forEach(fileItems =>{
                        if(this.checkedMap[fileItems.id] != undefined) {
                            ids.push(this.checkedMap[fileItems.id].id)
                        }
                    })
                    this.$store.dispatch('doc/checkDocumentHasShare',ids).then((res)=>{
                    let {data:hasData,message:hasMessage,code:hasCode} = res
                    if(hasCode==0){
                        if(hasData.length>0){
                            this.$confirm('删除文件中存在共享文件, 是否继续删除?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                            }).then(() => {
                               this.removeMoreMyFile(ids)
                            }).catch(() => {         
                            });
                        } else {
                               this.removeMoreMyFile(ids)
                        }
                    } else {
                        this.$message.error(hasMessage)
                    }
                })

                } else {
                    this.$message.error('请选择数据')
                }

            }
        },
        removeMoreMyFile(ids){
            this.$store.dispatch("doc/checkedRemove", {
                vkey: this.viewerKey,
                id: ids
            }).then((data) => {
                // 文件夹类型的时候 删除刷新左侧菜单
                if(data) {
                    this.loadAndRefresh()
                    this.$store.dispatch('login/getUserUsedDist')
                    this.$message.success('删除成功')
                } else {
                    this.$message.error('删除失败')
                    // this.$message.error("当前文件中有未关闭的共享，请先关闭共享")
                }
            }).catch(err => this.$message.error(err))
        },
        removeFewMyFile(row){
            this.$store.dispatch("doc/checkedRemove", {
                vkey: this.viewerKey,
                id: [row.id]
            }).then((data) => {
                this.loadingHide()
                // 文件夹类型的时候 删除刷新左侧菜单
                if(data){
                    this.loadAndRefresh()
                     this.$store.dispatch('login/getUserUsedDist')
                    this.$message.success('删除成功')
                } else {
                    this.$message.error('删除失败')
                }
            }).catch(err => this.$message.error(err))
        },
        // 是否开启了在线编辑功能
        getValueByKey(){
            msgAPI.getValueByKey('system.edit.enable').then(res => {
                if(res.code == 0){
                    this.isEditing  = res.data.configValue;
                     // 如果已经开启了在线编辑功能，那么检测是用哪种方式
                    if(this.isEditing == '1'){
                        msgAPI.getValueByKey('system.edit.type').then(res => {
                            this.editingType = res.data.configValue;
                        })
                    }
                }
            })
        }
    },
    beforeDestroy() {
        this.$ebus.$off('uploadFileBtn');
        this.$ebus.$off('addNewFileBtn');
        this.$ebus.$off('changeRadioEvent');
        this.$ebus.$off('changeInputEvent');
        this.$ebus.$off('callBackTab');
        this.$ebus.$off('myChildDocBackBtn');
        this.$ebus.$off('myDocEnterChild');
        this.$ebus.$off('enterMyDocData');
        this.$ebus.$off('handleMyShareEvent');
        this.$ebus.$off('handleMyDocRemoveClickEvent');
    }
}
</script>
<style lang="less" scoped>
@import "../../styles/index";
.card-container{
    .warm-row{
        background: #ccc!important;
        opacity: 0.6;
        .uploading{
            position: absolute;
            right: 0;
            top:0;
            font-size: 14px;
            background: #E6A23C;
            display: inline-block;
            border-top-left-radius: 15px;
            border-bottom-right-radius: 15px;
            color: #fff;
            padding: 5px 10px;
            z-index:1;
        }
        .document-bottom{
            .el-dropdown{
                pointer-events: none;
                cursor: not-allowed;
            }
            .el-button{
                pointer-events: none;
                cursor: not-allowed;
            }
        }
        
    }
}
.tools-panel {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    color: #666666;
    cursor: pointer;
}
.el-row {
    height:calc(100vh - 265px);
    overflow: auto;
    .el-col-5 {
        width: 19.83%;
    }
}
/deep/ .el-table {
    .warm-row{
        background: #ccc!important;
       
        opacity: 0.6;
        td{
            pointer-events: none;
            cursor: not-allowed;
        }
        td:nth-child(1){
            pointer-events: initial;
            cursor: inherit;
        }
    }
    .uploading{
        background: #E6A23C;
        display: inline-block;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        color: #fff;
        padding: 0 10px;
    }
    .file-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        cursor: pointer;
        div:nth-child(1) {
            display: flex;
            img {
                width: 25px;
                height: 25px;
                margin-right: 5px;
            }
            span {
                display: block;
                width: 240px;
                height: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-line-clamp: 1;
                white-space: nowrap;
                // -webkit-box-orient: vertical;
            }
        }
        .btns {
            position: absolute;
            right: 0;
            width: 100px;
            margin: 0 0 0 20px;
        }
    }
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
                background: #F2F2F2;
                color: #666;
            }
        }
    }
    .el-table__body-wrapper {
        .el-table__expand-icon {
            display: none;
        }
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
.document {
    // height: 400px;
    // background: red;
    text-align: center;
    // border: 1px solid @Brand;
    margin-bottom: 20px;
    cursor: pointer;
    user-select: none;
}
.document-top {
    position: relative;
    padding: 10px;
    height: 200px;
    overflow: hidden;
}
.document-top img {
    width: 100%;
    height: 100%;
}
.document-middle {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: @h6;
    height: 97px;
    overflow: hidden;
    padding: 10px 20px;
}
.document-bottom {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .btn{
        font-size: 16px;
    }
    .checkbox-span {
      width: 13px;
      height: 13px;
      border-radius: 3px;
      border: 1px solid #409EFF;
    }
    .checkbox-span1 {
      width: 13px;
      height: 13px;
      border-radius: 3px;
      border: 1px solid #409eFF;
      background: #409EFF;
      color: #fff;
      position: relative;
      i {
        font-size: 12px;
        position: absolute;
        top: 1px;
        right: 1px;
      }
    }
}
.title {
    text-align: left;
    font-size: @h4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin: 10px 0;
}
.info-tag {
    margin-top: 5px;
}

.info-tag i {
    margin-right: 10px;
}
.red_share {
    margin-top: 10px;
    color: @Info;
}
.more {
    top: 10px;
    right: 10px;
    position: absolute;
}
.more-menu {
    font-size: 18px;
}
.pagebox {
    margin-top: 20px;
    text-align: left;
}
.no-data-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    margin-right: 10%;
    img {
        width: 380px;
        height: 280px;
    }
    span {
        color: #bdc1cd;
        font-size: 16px;
        margin-top: 15px;
        font-weight: 100px;
    }
}
</style>